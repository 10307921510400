import React, { forwardRef, Ref, useEffect, useRef } from 'react';
import WorkCard from './WorkCard';
import styles from './work.module.scss'

interface WorkProps {
    workRef: React.RefObject<HTMLDivElement>;
}

const Work: React.FC<WorkProps> = ({ workRef }) => {
    
    const handleCreseClick = () => {
        window.open('https://www.cresasm.ro/', '_blank');
    };

    const handleAbcClick = () => {
        window.open('https://abcautostart.com/', '_blank');
    };

    return (
        <div className={styles.work} ref={workRef}>
            <div className={styles.title}>
                Explore our work
            </div>

            <div className={styles.workColumn}>
                <div className={styles.workRow}>
                    <div>
                        <WorkCard img='images/cresasm2.png' title='Satu Mare County Nurseries' onClick={handleCreseClick} />
                    </div>
                    <div>
                        <WorkCard img='images/abc2.png' title='ABC Auto Start Driving School' onClick={handleAbcClick} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Work;