import styles from './button.module.scss'

interface ButtonProps {
    title: string;
    active: boolean;
    onClick: any;
}

function Button(props: ButtonProps) {
    return (
        <div className={props.active ? styles.buttonActive : styles.buttonInactive} onClick={props.onClick}>
            {props.title}
        </div>
    )
}

export default Button;