import styles from './description.module.scss'

function Description() {
    return (
        <div className={styles.description}>
            <div className={styles.title}>
                We're here to transform <br /> your <span className={styles.strongy}>dreams</span> into <span className={styles.strongy}>reality.</span>
            </div>

            <div className={styles.text}>
                At our core, we're all about making your journey smooth and efficient. Imagine <br />
                having access to top-notch IT services and software solutions that not only fit your <br />
                budget but also slash development time to get your projects off the ground swiftly.<br />
            </div>
        </div>
    )
}

export default Description;