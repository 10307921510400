import { Img } from 'react-image';
import styles from './workCard.module.scss'

interface WorkCardProps {
    img: string;
    title: string;
    onClick: any;
}

function WorkCard(props: WorkCardProps) {
    return (
        <div className={styles.workCard} onClick={props.onClick}>
            <Img src={props.img} className={styles.img} />

            <div className={styles.title}>
                {props.title}
            </div>
        </div>
    )
}

export default WorkCard;