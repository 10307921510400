import { Img } from 'react-image';
import styles from './App.module.scss'
import Contact from './Contact';
import Description from './Description';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';
import Work from './Work';

function App() {
  const workRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Header workRef={workRef} contactRef={contactRef} />
      <div className={styles.app}>
        <Img src='images/background.png' className={styles.image} />
        <Description />
        <Work workRef={workRef} />
        <Contact contactRef={contactRef} />
        <Footer />
      </div>
    </>
  );
}

export default App;
