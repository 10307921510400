import { Img } from 'react-image';
import styles from './header.module.scss'
import Button from './Button';
import { useEffect, useState } from 'react';

interface HeaderProps {
    workRef: React.RefObject<HTMLDivElement>;
    contactRef: React.RefObject<HTMLDivElement>;
}

const Header: React.FC<HeaderProps> = ({ workRef, contactRef }) => {
    const [active, setActive] = useState<string>('Work');

    const handleClickWork = () => {
        setActive('Work');
        handleWorkClick();
    };

    const handleClickContact = () => {
        setActive('Contact');
        handleContactClick();
    };

    const handleWorkClick = () => {
        if (workRef.current) {
            workRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleContactClick = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.target === workRef.current && entry.isIntersecting) {
                        clearTimeout(timeout);
                        timeout = setTimeout(() => {
                            setActive('Work');
                        }, 300); // 1 second delay
                    } else if (entry.target === contactRef.current && entry.isIntersecting) {
                        clearTimeout(timeout);
                        timeout = setTimeout(() => {
                            setActive('Contact');
                        }, 300); // 1 second delay
                    }
                });
            },
            { threshold: 0.7 } // Adjust threshold as needed
        );

        if (workRef.current) {
            observer.observe(workRef.current);
        }
        if (contactRef.current) {
            observer.observe(contactRef.current);
        }

        return () => {
            clearTimeout(timeout);
            if (workRef.current) {
                observer.unobserve(workRef.current);
            }
            if (contactRef.current) {
                observer.unobserve(contactRef.current);
            }
        };
    }, []);


    return (
        <div className={styles.header}>
            <Img src="images/logo.svg" className={styles.logo} />
            <div className={styles.buttons}>
                <Button title='Work' active={active === 'Work'} onClick={handleClickWork} />
                <Button title='Contact' active={active === 'Contact'} onClick={handleClickContact} />
            </div>
        </div>
    )
}

export default Header;