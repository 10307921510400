import styles from './footer.module.scss'

function Footer() {
    return (
        <div className={styles.footer}>
            © ULMSOFT 2024, ALL RIGHTS RESERVED
        </div>
    )
}

export default Footer;