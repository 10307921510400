import styles from './contact.module.scss'

interface ContactProps {
    contactRef: React.RefObject<HTMLDivElement>;
}

const Contact: React.FC<ContactProps> = ({ contactRef }) => {
    return (
        <div className={styles.contact} ref={contactRef} >
            <div className={styles.firstHalf}>
                <div>Get in touch!</div>
                <div>
                    <div className={styles.detaliu}>
                        <div>Email</div>
                        <div className={styles.line}></div>
                        <div>tudor.ulmeanu@yahoo.com</div>
                    </div>
                    <div className={styles.detaliu}>
                        <div>Phone Number (RO)</div>
                        <div className={styles.line}></div>
                        <div>+40 (741) 311 706</div>
                    </div>
                    <div className={styles.detaliu}>
                        <div>Phone Number (UK)</div>
                        <div className={styles.line}></div>
                        <div>+44 (774) 377 6211</div>
                    </div>
                </div>

            </div>

            <div className={styles.secoundHalf}>
                <div className={styles.field}>Email*</div>
                <input className={styles.inputBox} type="text" placeholder="Your email" />
                <div className={styles.field}>Name</div>
                <input className={styles.inputBox} type="text" placeholder="Your name / company" />
                <div className={styles.field}>Subject*</div>
                <input className={styles.inputBox} type="text" placeholder="Email subject" />
                <div className={styles.field}>Message*</div>
                <input className={styles.inputBox} type="text" placeholder="Your message" />

                <div className={styles.button}>
                    <div className={styles.buttonText}>Send Message</div>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_544)">
                            <path d="M2.21796 1.0056C1.15135 0.542787 0.100057 1.68909 0.651754 2.71279L2.77578 6.65742C2.91064 6.91182 3.16197 7.08039 3.44702 7.11717L8.84138 7.79147C8.94559 7.80373 9.02528 7.89261 9.02528 7.99682C9.02528 8.10103 8.94559 8.18991 8.84138 8.20217L3.44702 8.87647C3.16197 8.91325 2.91064 9.08489 2.77578 9.33622L0.651754 13.287C0.100057 14.3107 1.15135 15.457 2.21796 14.9942L15.7621 9.12473C16.746 8.6987 16.746 7.30107 15.7621 6.87504L2.21796 1.0056Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_544">
                                <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Contact;